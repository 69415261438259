'use client';

import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Wrapper } from '@/components';
import { LinkIconButton, Picture } from '@/compositions';
import { HorizontalAlignment } from '@/entities/Alignment/Alignment';
import { Image } from '@/entities/Media/Media';
import { TagLink } from '@/entities/TagLink/TagLink';
import useFormatDate from '@/hooks/useFormatDate';
import useTrans from '@/hooks/useTrans';

import './ImageTextBlock.scss';

interface ImageTextBlockProps {
    image: Image;
    imagePosition?: HorizontalAlignment;
    title: string;
    description?: string;
    ctaLink?: string;
    hasBorders?: boolean;
    tag?: TagLink;
    publishedDate?: string;
    wrapperClassName?: string;
    className?: string;
}

export const ImageTextBlock: FC<ImageTextBlockProps> = ({
    image,
    imagePosition,
    title,
    description,
    ctaLink,
    hasBorders,
    tag,
    publishedDate,
    wrapperClassName = '',
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const formattedPublishedDate = formatDate(publishedDate, {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });

    const imageTextBlockWrapperClassNames = classNames('image-text-block__wrapper', {
        'image-text-block__wrapper--has-borders': hasBorders,
        [`image-text-block__wrapper--image-${imagePosition}`]: imagePosition,
    }, wrapperClassName);

    return (
        <div className={`image-text-block ${className}`}>
            <Wrapper>
                <div className={imageTextBlockWrapperClassNames}>
                    <div className="image-text-block__image-wrapper">
                        <Picture
                            {...image}
                            className="image-text-block__picture"
                            imageClassName="image-text-block__image"
                        />
                    </div>

                    <div className="image-text-block__content-wrapper">
                        <div className="image-text-block__content-wrapper-up">
                            {tag && publishedDate && (
                                <p className="image-text-block__tag-and-published-date">
                                    <span>
                                        {tag.name}
                                    </span>

                                    <span className="image-text-block__published-date">
                                        {formattedPublishedDate}
                                    </span>
                                </p>
                            )}

                            <h2 className="image-text-block__title">
                                {title}
                            </h2>
                        </div>

                        {description && (
                            <p className="image-text-block__description">
                                {description}
                            </p>
                        )}

                        {ctaLink && (
                            <LinkIconButton
                                href={ctaLink}
                                icon="arrow-short-right"
                                iconPos={HorizontalAlignment.right}
                                text={trans('common.readMore')}
                                className="image-text-block__button"
                            />
                        )}
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};
