import { Link } from '@/entities/Link/Link';
import { Product } from '@/entities/Product/Product';

export enum FeaturedProductsType {
    alternatingSizes = 'alternating-sizes',
    verticalTitle = 'vertical-title',
}

export interface FeaturedProductsInterface {
    type: FeaturedProductsType;
    title: string;
    products: Product[];
    link?: Link;
}
