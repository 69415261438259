'use client';

import { FC, ReactElement } from 'react';

import Link from 'next/link';

import { Logo } from '@/components/Logo/Logo';
import useTrans from '@/hooks/useTrans';

import './FooterBottom.scss';

interface FooterBottomProps {
    className?: string;
}

export const FooterBottom: FC<FooterBottomProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const currentYear = new Date().getFullYear();

    return (
        <div className={`footer-bottom ${className}`}>
            <div className="footer-bottom__content footer-bottom__content-left">
                <Link href="/">
                    <Logo className="footer-bottom__logo" />
                </Link>

                <p className="footer-bottom__text footer-bottom__since">
                    {trans('containers.footer.since')}
                </p>

                <p className="footer-bottom__text footer-bottom__year">
                    {`© ${currentYear}`}
                </p>
            </div>

            <div className="footer-bottom__content footer-bottom__content-right">
                <Link href="/privacy" className="footer-bottom__link">
                    {trans('containers.footer.privacy')}
                </Link>

                <Link href="/disclaimer" className="footer-bottom__link">
                    {trans('containers.footer.disclaimer')}
                </Link>

                <Link href="/cookie-policy" className="footer-bottom__link">
                    {trans('containers.footer.cookiePolicy')}
                </Link>
            </div>
        </div>
    );
};
