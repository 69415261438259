import { Article } from '@/entities/Article/Article';
import { MOCK_IMAGE_4, MOCK_IMAGE_5, MOCK_IMAGE_6 } from '@/mock/mock-data/media';

export const MOCK_ARTICLES: Article[] = [
    {
        id: '1',
        title: 'Bold & bijzonder - van binnen naar buiten',
        slug: 'bold-bijzonder-van-binnen-naar-buiten',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishedDate: '2024-04-10T14:30:00.000Z',
        image: MOCK_IMAGE_6,
    },
    {
        id: '2',
        title: 'Zo dek je een sfeervolle paastafel',
        slug: 'zo-dek-je-een-sfeervolle-paastafel',
        tag: {
            slug: 'nieuws',
            name: 'Nieuws',
        },
        publishedDate: '2024-02-06T14:30:00.000Z',
        image: MOCK_IMAGE_5,
    },
    {
        id: '3',
        title: '5 items voor tuin & balkon',
        slug: '5-items-voor-tuin-balkon',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishedDate: '2023-10-27T14:30:00.000Z',
        image: MOCK_IMAGE_4,
    },
    {
        id: '4',
        title: 'Bold & bijzonder - van binnen naar buiten',
        slug: 'bold-bijzonder-van-binnen-naar-buiten',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishedDate: '2024-04-10T14:30:00.000Z',
        image: MOCK_IMAGE_6,
    },
    {
        id: '5',
        title: 'Zo dek je een sfeervolle paastafel',
        slug: 'zo-dek-je-een-sfeervolle-paastafel',
        tag: {
            slug: 'nieuws',
            name: 'Nieuws',
        },
        publishedDate: '2024-02-06T14:30:00.000Z',
        image: MOCK_IMAGE_5,
    },
    {
        id: '6',
        title: '5 items voor tuin & balkon',
        slug: '5-items-voor-tuin-balkon',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishedDate: '2023-10-27T14:30:00.000Z',
        image: MOCK_IMAGE_4,
    },
];
