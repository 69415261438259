'use client';

import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import Link from 'next/link';

import { Wrapper } from '@/components';
import { TopNavbarUsp, UspScroller } from '@/containers/@blocks/TopNavbar/subcomponents';
import { TopNavbarInterface } from '@/entities/@blocks/TopNavbar/TopNavbar';

import './TopNavbar.scss';

interface TopNavbarProps extends TopNavbarInterface {
    className?: string;
}

export const TopNavbar: FC<TopNavbarProps> = ({
    usps,
    links,
    className = '',
}): ReactElement => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [previousIndex, setPreviousIndex] = useState<number>();

    useEffect((): () => void => {
        const interval = setInterval((): void => {
            setTimeout((): void => {
                setPreviousIndex(currentIndex);
                setCurrentIndex(prevIndex => (prevIndex + 1) % usps.length);
            }, 500);
        }, 3000);

        return (): void => {
            clearInterval(interval);
        };
    }, [currentIndex, usps.length]);

    return (
        <div className={`top-navbar ${className}`}>
            <UspScroller usps={usps} />

            <Wrapper className="top-navbar__wrapper">
                <div className="top-navbar__desktop-wrapper">
                    <div className="top-navbar__usps-wrapper">
                        <div className="top-navbar__text-carousel">
                            {usps.map((usp, index) => {
                                const uspClassNames = classNames('top-navbar__carousel-item', {
                                    'top-navbar__carousel-item--is-active': index === currentIndex,
                                    'top-navbar__carousel-item--is-leaving': index === previousIndex,
                                });

                                return (
                                    <TopNavbarUsp
                                        {...usp}
                                        key={usp.id}
                                        className={uspClassNames}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <ul className="top-navbar__links-wrapper">
                        {links.map(link => (
                            <li key={link.label} className="top-navbar__link-item">
                                <Link href={link.href}>
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </Wrapper>
        </div>
    );
};
