import { FC, ReactElement } from 'react';

import { TopNavbarUsp } from '@/containers/@blocks/TopNavbar/subcomponents/TopNavbarUsp/TopNavbarUsp';
import { TopNavbarUsp as TopNavbarUspInterface } from '@/entities/@blocks/TopNavbar/TopNavbar';

import './UspScroller.scss';

interface UspScrollerProps {
    usps: TopNavbarUspInterface[];
    className?: string;
}

export const UspScroller: FC<UspScrollerProps> = ({
    usps,
    className = '',
}): ReactElement => {
    const clonedUsps = [...usps, ...usps].map((item, index) => ({
        ...item,
        id: `${item.id}-${index}`,
    }));

    return (
        <div className={`usp-scroller ${className}`}>
            <ul className="usp-scroller__list">
                {clonedUsps.map(usp => (
                    <li key={usp.id} className="usp-scroller__item">
                        <TopNavbarUsp
                            {...usp}
                            key={usp.id}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
