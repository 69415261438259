import { Product } from '@/entities/Product/Product';
import {
    MOCK_PRODUCT_IMAGE_1,
    MOCK_PRODUCT_IMAGE_2,
    MOCK_PRODUCT_IMAGE_3,
    MOCK_PRODUCT_IMAGE_4,
    MOCK_PRODUCT_IMAGE_5,
    MOCK_PRODUCT_IMAGE_6,
    MOCK_PRODUCT_IMAGE_7,
} from '@/mock/mock-data/media';

export const MOCK_PRODUCT_1 = {
    id: '1',
    name: 'Bijzettafel Mala glossy',
    brand: {
        id: '1',
        name: 'Loods 5',
        slug: 'loods-5',
    },
    images: [
        MOCK_PRODUCT_IMAGE_1,
    ],
    price: 125.00,
};

export const MOCK_PRODUCT_2 = {
    id: '2',
    name: 'Kussen Maysa',
    brand: {
        id: '1',
        name: 'Loods 5',
        slug: 'loods-5',
    },
    images: [
        MOCK_PRODUCT_IMAGE_2,
    ],
    price: 24.95,
};

export const MOCK_PRODUCT_3 = {
    id: '3',
    name: 'Salontafel Lilla',
    brand: {
        id: '1',
        name: 'Loods 5',
        slug: 'loods-5',
    },
    images: [
        MOCK_PRODUCT_IMAGE_3,
    ],
    price: 125.00,
};

export const MOCK_PRODUCT_4 = {
    id: '4',
    name: 'Vaas Loa 12x26x31cm',
    brand: {
        id: '1',
        name: 'Loods 5',
        slug: 'loods-5',
    },
    images: [
        MOCK_PRODUCT_IMAGE_4,
    ],
    price: 29.95,
};

export const MOCK_PRODUCT_5 = {
    id: '5',
    name: 'Bijzettafel Naomi',
    brand: {
        id: '1',
        name: 'Loods 5',
        slug: 'loods-5',
    },
    images: [
        MOCK_PRODUCT_IMAGE_5,
    ],
    price: 135.00,
};

export const MOCK_PRODUCT_6 = {
    id: '6',
    name: 'Poef Ollie',
    brand: {
        id: '1',
        name: 'Loods 5',
        slug: 'loods-5',
    },
    images: [
        MOCK_PRODUCT_IMAGE_6,
    ],
    price: 89.95,
};

export const MOCK_PRODUCT_7 = {
    id: '7',
    name: '3-zitsbank stand alon Fat Joe royal',
    brand: {
        id: '1',
        name: 'Loods 5',
        slug: 'loods-5',
    },
    images: [
        MOCK_PRODUCT_IMAGE_7,
    ],
    price: 1995.00,
};

export const MOCK_PRODUCTS: Product[] = [
    MOCK_PRODUCT_1,
    MOCK_PRODUCT_2,
    MOCK_PRODUCT_3,
];
