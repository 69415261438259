'use client';

import {
    CSSProperties,
    FC,
    ReactElement,
    useState,
} from 'react';

import Link from 'next/link';

import { IconButton } from '@/compositions';
import { UrgentBannerInterface } from '@/entities/@blocks/UrgentBanner/UrgentBanner';
import useTrans from '@/hooks/useTrans';

import './UrgentBanner.scss';

interface UrgentBannerProps extends UrgentBannerInterface {
    className?: string;
}

export const UrgentBanner: FC<UrgentBannerProps> = ({
    text,
    link,
    backgroundColor,
    textColor,
    className = '',
}): ReactElement | null => {
    const trans = useTrans();

    const [isHidden, setIsHidden] = useState<boolean>(false);

    const cssVariables = {
        '--c-urgent-banner-text': textColor,
        '--c-urgent-banner-background': backgroundColor,
    } as CSSProperties;

    const handleClose = (): void => {
        setIsHidden(true);
    };

    if (isHidden) {
        return null;
    }

    return (
        <div style={cssVariables} className={`urgent-banner ${className}`}>
            <p className="urgent-banner__description">
                {text}
            </p>

            <Link
                href={link.href}
                target={link.target}
                className="urgent-banner__link"
            >
                {link.label}
            </Link>

            <IconButton
                text={trans('common.close')}
                hideLabel
                icon="cross"
                onClick={handleClose}
                className="urgent-banner__close-button"
            />
        </div>
    );
};
