'use client';

import { FC, ReactElement, useMemo } from 'react';

import { Wrapper } from '@/components';
import { SectionFooter } from '@/compositions';
import { BrandsCarouselInterface, Logo } from '@/entities/@blocks/BrandsCarousel/BrandsCarousel';
import { LinkTarget } from '@/entities/Link/Link';
import useTrans from '@/hooks/useTrans';

import { BrandsCarouselItems } from './subcomponents/BrandsCarouseltems/BrandsCarouselItems';

import './BrandsCarousel.scss';

interface BrandsCarouselProps extends BrandsCarouselInterface {
    logos: Logo[];
    title: string;
    className?: string;
}

export const BrandsCarousel: FC<BrandsCarouselProps> = ({
    logos,
    title,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    /**
     * Ensures there are enough logos to fill the carousel by duplicating them as necessary
     */

    const duplicatedLogos = useMemo(() => {
        const minimumLogosToFillCarousel = 6;

        if (logos.length >= minimumLogosToFillCarousel) {
            return logos;
        }

        const timesToDuplicate = Math.ceil(minimumLogosToFillCarousel / logos.length);

        return Array(timesToDuplicate)
            .fill(logos)
            .flat()
            .map((logo, index) => ({
                ...logo,
                id: `${index}-${logo.id}`,
            }));
    }, [logos]);

    return (
        <div className={`brands-carousel ${className}`}>
            <Wrapper>
                <h2 className="brands-carousel__title">
                    {title}
                </h2>
            </Wrapper>

            <div className="brands-carousel__wrapper">
                <BrandsCarouselItems logos={duplicatedLogos} className="brands-carousel__items-wrapper" />
                <BrandsCarouselItems logos={duplicatedLogos} className="brands-carousel__items-wrapper" />
            </div>

            <Wrapper className="brands-carousel__footer-wrapper">
                <SectionFooter
                    link={{
                        href: '/brands',
                        label: trans('containers.brandsCarousel.showAllBrands'),
                        target: LinkTarget.self,
                    }}
                />
            </Wrapper>
        </div>
    );
};
