import { Link } from '@/entities/Link/Link';

export enum TopNabvarUspType {
    text = 'text',
    rating = 'rating',
}

export interface TopNavbarRating {
    url: string;
    stars: number;
}

export interface TopNavbarUsp {
    id: string;
    type: TopNabvarUspType;
    data: string | TopNavbarRating;
}

export interface TopNavbarInterface {
    usps: TopNavbarUsp[];
    links: Link[];
}
