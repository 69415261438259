export const MOCK_VIDEO = {
    src: '/mock/video/video-1.mp4',
    type: 'video/mp4',
};

export const MOCK_IMAGE_1 = {
    src: '/mock/image/impression-1-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/impression-1-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/impression-1-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/impression-1-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/impression-1-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een impressie van een bruine bank met witte kussens en een opgeslagen tijdschrift',
};

export const MOCK_IMAGE_2 = {
    src: '/mock/image/impression-2-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/impression-2-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/impression-2-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/impression-2-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/impression-2-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een sfeerbeeld van een huiskamer waar zonlicht door de gordijnen komt. Naast het raam staat een kamerplant',
};

export const MOCK_IMAGE_3 = {
    src: '/mock/image/impression-3-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/impression-3-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/impression-3-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/impression-3-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/impression-3-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een impressie van een voorkamer met lichte meubels en een sfeervolle bijzettafel',
};

export const MOCK_IMAGE_4 = {
    src: '/mock/image/impression-4-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/impression-4-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/impression-4-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/impression-4-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/impression-4-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een stijlvolle woonkamer met meubels met een lichte kleur op een rond tapijt. Kamerplanten brengen extra sfeer',
};

export const MOCK_IMAGE_5 = {
    src: '/mock/image/impression-5-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/impression-5-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/impression-5-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/impression-5-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/impression-5-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een impressie van een hoek uit een woonkamer met bijzettafel en kamerplant',
};

export const MOCK_IMAGE_6 = {
    src: '/mock/image/impression-6-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/impression-6-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/impression-6-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/impression-6-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/impression-6-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een impressie vanuit een tuin op een lounge set',
};

export const MOCK_IMAGE_7 = {
    src: '/mock/image/impression-5-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/impression-7-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/impression-7-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/impression-7-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/impression-7-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een impressie van een lounge set voor binnen',
};

export const MOCK_IMAGE_BANNER_1 = {
    src: '/mock/image/banner-1-large.webp',
    formats: {
        large: {
            srcSet: '/mock/image/banner-1-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        small: {
            srcSet: '/mock/image/banner-1-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een impressie van een woonkamer met een bank en een tafel',
};

export const MOCK_PRODUCT_IMAGE_1 = {
    src: '/mock/image/product-1-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/product-1-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/product-1-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/product-1-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/product-1-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een okergele bijzettafel',
};

export const MOCK_PRODUCT_IMAGE_2 = {
    src: '/mock/image/product-2-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/product-2-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/product-2-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/product-2-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/product-2-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een beige kussen met oranje patroon',
};

export const MOCK_PRODUCT_IMAGE_3 = {
    src: '/mock/image/product-3-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/product-3-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/product-3-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/product-3-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/product-3-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een glazen bijzettafel',
};

export const MOCK_PRODUCT_IMAGE_4 = {
    src: '/mock/image/product-4-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/product-4-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/product-4-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/product-4-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/product-4-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een grijze vaas met grote oren',
};

export const MOCK_PRODUCT_IMAGE_5 = {
    src: '/mock/image/product-5-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/product-5-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/product-5-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/product-5-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/product-5-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een roze bijzettafel van transparant hard plastic',
};

export const MOCK_PRODUCT_IMAGE_6 = {
    src: '/mock/image/product-6-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/product-6-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/product-6-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/product-6-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/product-6-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een okergele poef met twee grote poten',
};

export const MOCK_PRODUCT_IMAGE_7 = {
    src: '/mock/image/product-7-original.webp',
    formats: {
        original: {
            srcSet: '/mock/image/product-7-original.webp',
            type: 'image/webp',
            media: '(min-width: 1024px)',
        },
        large: {
            srcSet: '/mock/image/product-7-large.webp',
            type: 'image/webp',
            media: '(min-width: 768px)',
        },
        medium: {
            srcSet: '/mock/image/product-7-medium.webp',
            type: 'image/webp',
            media: '(min-width: 480px)',
        },
        small: {
            srcSet: '/mock/image/product-7-small.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Een bruine 3-zitsbank',
};
